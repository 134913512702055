import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32')
];

export const server_loads = [];

export const dictionary = {
		"/": [6],
		"/login": [7],
		"/login/forgot": [8],
		"/login/reset": [9],
		"/privacy": [10],
		"/privacy/delete": [11],
		"/project/[id]": [12,[2]],
		"/project/[id]/calculation": [13,[2,3]],
		"/project/[id]/calculation/configurator": [14,[2,3]],
		"/project/[id]/calculation/gaeb": [15,[2,3]],
		"/project/[id]/debug": [16,[2]],
		"/project/[id]/design": [17,[2,4]],
		"/project/[id]/design/separations": [18,[2,4]],
		"/project/[id]/design/surfaces": [19,[2,4]],
		"/project/[id]/design/visualization": [20,[2,4]],
		"/project/[id]/details": [21,[2]],
		"/project/[id]/preparation": [22,[2,5]],
		"/project/[id]/preparation/camera": [23],
		"/project/[id]/preparation/dimensions": [24,[2,5]],
		"/project/[id]/preparation/facades": [25,[2,5]],
		"/project/[id]/preparation/insulation": [26,[2,5]],
		"/project/[id]/preparation/openings": [27,[2,5]],
		"/project/[id]/requirements": [28,[2]],
		"/project/[id]/variants": [29,[2]],
		"/register": [30],
		"/terms": [31],
		"/user-settings": [32]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';